import React, { useState } from 'react'
import { observer, inject } from 'mobx-react'
import { navigate } from 'gatsby'
import { Formik } from 'formik'
import styled from 'styled-components'
import { rem, flex } from 'styled-tidy'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import checkoutConfig from 'config/checkout'
import theme from 'lib/styles/theme'
import CartSummary from 'components/cart-summary'
import Plus from 'components/icons/plus'
import BusyMessage from 'components/busy-message'
import {
  Form,
  Title,
  Input,
  NakedInputWrap,
  Error,
  RowSplit,
  RowSplitItem,
  SubmitButton,
} from 'components/forms/styles'

const { colors, speeds } = theme
const { white, silver, iron, black } = colors

const PlusWrap = styled.span`
  ${flex('row', 'center', 'center')}
  background: transparent;
  box-shadow: inset 0 0 0 ${rem(1)} ${silver};
  cursor: pointer;
  height: ${rem(32)};
  margin: 0 ${rem(8)} 0 0;
  transition: background ${speeds.quick}ms ease-in-out;
  width: ${rem(32)};

  svg {
    fill: ${iron};
    transition: fill ${speeds.quick}ms ease-in-out;
  }

  :hover {
    background: ${black};
    box-shadow: none;

    svg {
      fill: ${white};
    }
  }
`

const CheckoutForm = ({ cart: cartStore }) => {
  const [isCreatingOrder, setIsCreatingOrder] = useState(false)
  const [cartSummaryExpanded, setCartSummaryExpanded] = useState(false)
  const { items, checkout, order, orderError } = cartStore

  if (!items.length) {
    if (global.window) navigate('/cart')
    return null
  }

  const validate = values => {
    let errors = {}

    if (!values.shippingName) {
      errors.shippingName = 'Required'
    }
    if (!values.email) {
      errors.email = 'Required'
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address'
    }
    if (!values.shippingCountry) errors.shippingCountry = 'Required'
    if (!values.shippingRegion) errors.shippingRegion = 'Required'
    if (!values.shippingCity) errors.shippingCity = 'Required'
    if (!values.shippingZip) errors.shippingZip = 'Required'
    if (!values.shippingAddress) errors.shippingAddress = 'Required'
    return errors
  }

  const submit = async formValues => {
    cartStore.setCheckout(formValues)
    setIsCreatingOrder(true)
    const success = await cartStore.createOrder()
    setIsCreatingOrder(false)
    if (success && global.window) navigate(`/pay`)
  }

  return (
    <Formik
      initialValues={checkout}
      isInitialValid={!Object.keys(validate(checkout)).length}
      validate={validate}
      onSubmit={submit}
      validateOnBlur={false}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isValid,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Title>Checkout</Title>
          <Input
            type="text"
            name="shippingName"
            autoComplete="name"
            placeholder="Full name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.shippingName}
          />
          <span id="card-element" />
          {touched.shippingName && errors.shippingName && (
            <Error>{errors.shippingName}</Error>
          )}
          <Input
            type="email"
            name="email"
            autoComplete="email"
            placeholder="Email address"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          {touched.email && errors.email && <Error>{errors.email}</Error>}
          <Title>Shipping</Title>
          <NakedInputWrap field="select" hasValue={!!values.shippingCountry}>
            <CountryDropdown
              name="shippingCountry"
              value={values.shippingCountry}
              valueType="short"
              onChange={val => setFieldValue('shippingCountry', val)}
              whitelist={checkoutConfig.whitelistCountries}
            />
          </NakedInputWrap>
          {touched.shippingCountry && errors.shippingCountry && (
            <Error>{errors.shippingCountry}</Error>
          )}
          {(values.shippingCountry ||
            (touched.shippingRegion && errors.shippingRegion)) && (
            <>
              <NakedInputWrap field="select" hasValue={!!values.shippingRegion}>
                <RegionDropdown
                  name="shippingRegion"
                  value={values.shippingRegion}
                  valueType="short"
                  country={values.shippingCountry}
                  countryValueType="short"
                  onChange={val => setFieldValue('shippingRegion', val)}
                />
              </NakedInputWrap>
              {touched.shippingRegion && errors.shippingRegion && (
                <Error>{errors.shippingRegion}</Error>
              )}
            </>
          )}
          <Input
            type="text"
            name="shippingAddress"
            autoComplete="street-address"
            placeholder="Address"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.shippingAddress}
          />
          {touched.shippingAddress && errors.shippingAddress && (
            <Error>{errors.shippingAddress}</Error>
          )}
          <RowSplit split={[66, 34]}>
            <RowSplitItem>
              <Input
                type="text"
                name="shippingCity"
                autoComplete="address-level2"
                placeholder="City"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.shippingCity}
              />
              {touched.shippingCity && errors.shippingCity && (
                <Error>{errors.shippingCity}</Error>
              )}
            </RowSplitItem>
            <RowSplitItem>
              <Input
                type="text"
                name="shippingZip"
                autoComplete="postal-code"
                placeholder="Zip"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.shippingZip}
              />
              {touched.shippingZip && errors.shippingZip && (
                <Error>{errors.shippingZip}</Error>
              )}
            </RowSplitItem>
          </RowSplit>
          {!cartSummaryExpanded && (
            <Title>
              <PlusWrap onMouseDown={() => setCartSummaryExpanded(true)}>
                <Plus />
              </PlusWrap>
              Order Summary
            </Title>
          )}
          {cartSummaryExpanded && <CartSummary justSubtotal={!order.id} />}
          {orderError && <Error>{orderError}</Error>}
          <SubmitButton
            onMouseDown={handleSubmit}
            disabled={!isValid || isCreatingOrder}
          >
            Continue
          </SubmitButton>
          {isCreatingOrder && <BusyMessage>Just a Moment</BusyMessage>}
        </Form>
      )}
    />
  )
}

export default inject('cart')(observer(CheckoutForm))
