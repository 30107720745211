import React from 'react'
// import siteConfig from 'config/site'
import Layout from 'layouts/default'
import Wrap from 'components/wrap'
import CheckoutForm from 'components/forms/checkout-form'
// import Countdown from 'components/countdown'
import Title from 'components/title'
import Pargraph from 'components/paragraph'

const CheckoutPage = () => (
  <Layout path="/checkout">
    {process.env.GATSBY_EVILNEON_CART_ENABLED === 'true' ? (
      <Wrap>
        <CheckoutForm />
      </Wrap>
    ) : (
      <Wrap>
        <Title>Store Disabled</Title>
        <Pargraph>
          We're still developing our website. Check back soon.
        </Pargraph>
      </Wrap>
      // <Countdown date={siteConfig.launchDate}>
      //   <Title is="h2" align="center">{siteConfig.launchTitle}</Title>
      // </Countdown>
    )}
  </Layout>
)

export default CheckoutPage
